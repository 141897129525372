<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <v-data-table
            :footer-props="{
              pageText: `{0}-{1} ${$t('tables.of')} {2}`,
              itemsPerPageText: $t('tables.pricingZoneRelationsByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            }"
            :headers="pricingZoneRelationsHeaders"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="pricingZoneRelations"
            :search="searchPricingZoneRelationString"
            class="elevation-1 cursor-pointer"
            @click:row="editPricingZoneRelationItem"
            multi-sort>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ $t('tables.pricingZoneRelations') }}
                </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  v-model="searchPricingZoneRelationString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <v-spacer></v-spacer>

                <v-dialog v-model="pricingZoneRelationDialog" max-width="700px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mb-2" color="primary" dark v-bind="attrs" v-on="on">
                      {{ $t('buttons.add') }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t(pricingZoneRelationFormTitle) }}
                      <v-spacer></v-spacer>
                      <v-icon class="mdi mdi-close" style="color: white" @click="closePricingZoneRelationEdit"></v-icon>
                    </v-card-title>

                    <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
                      <v-form lazy-validation @submit.prevent="handleSubmit(savePricingZoneRelation)">
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" md="6" sm="12">
                                <validation-provider rules="required|max:200" v-slot="{ errors }" name="zone_one">
                                  <v-autocomplete
                                    v-model="editedPricingZoneRelationItem.zone_one_id"
                                    :items="pricingZones"
                                    clearable
                                    :label="$t('pricingZoneRelationsPage.zoneOne')"
                                    :no-data-text="$t('select.noDataAvailable')"
                                    @focus="$event.target.click()"
                                    :error-messages="errors"
                                    item-value="id"
                                    item-text="name">
                                  </v-autocomplete>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" md="6" sm="12">
                                <validation-provider rules="required|max:200" v-slot="{ errors }" name="zone_two">
                                  <v-autocomplete
                                    v-model="editedPricingZoneRelationItem.zone_two_id"
                                    :items="pricingZones"
                                    clearable
                                    :label="$t('pricingZoneRelationsPage.zoneTwo')"
                                    :no-data-text="$t('select.noDataAvailable')"
                                    @focus="$event.target.click()"
                                    :error-messages="errors"
                                    item-value="id"
                                    item-text="name">
                                  </v-autocomplete>
                                </validation-provider>
                              </v-col>
                              <v-col
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                                v-if="
                                  editedPricingZoneRelationItem.zone_one_id && editedPricingZoneRelationItem.zone_two_id
                                ">
                                <v-card
                                  class="px-5 my-5"
                                  v-for="(currency, currencyIndex) in currencies"
                                  :key="currencyIndex">
                                  <v-row>
                                    <v-col cols="12" md="12" sm="12">
                                      <span> {{ getCurrencyName(currency.id) }} </span>
                                    </v-col>

                                    <v-col
                                      cols="12"
                                      lg="4"
                                      md="4"
                                      sm="6"
                                      v-for="(item, index) in filteredPrices(
                                        editedPricingZoneRelationItem.zonePricingRelationPrices,
                                        currency
                                      )"
                                      :key="'price' + index">
                                      <validation-provider
                                        rules="required|numeric_decimal|max:200"
                                        v-slot="{ errors }"
                                        :name="'pricing_zone' + index"
                                        :custom-messages="{
                                          required: $t('customValidations.fieldRequired', {
                                            field: $t('names.price'),
                                          }),
                                          max: $t('customValidations.fieldLengthMax', {
                                            field: $t('names.price'),
                                            length: 200,
                                          }),
                                          numeric_decimal: $t('customValidations.numericDecimal', {
                                            field: $t('names.price'),
                                            length: 200,
                                          }),
                                        }">
                                        <v-text-field
                                          v-model="item.price"
                                          clearable
                                          counter="200"
                                          name="price"
                                          :label="getVehicleClassName(item.vehicle_class_id)"
                                          :error-messages="errors"></v-text-field>
                                      </validation-provider>
                                    </v-col>
                                  </v-row>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
                        </v-card-actions>
                      </v-form>
                    </validation-observer>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="pricingZoneRelationDialogDelete" max-width="650">
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ editedPricingZoneRelationItem.name }}
                      <v-spacer></v-spacer>
                      <v-icon
                        class="mdi mdi-close"
                        style="color: white"
                        @click="closePricingZoneRelationDelete"></v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <p>
                          {{ $t('pricingZoneRelationsPage.deleteMessage') }}
                        </p>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :disabled="!enablePricingZoneRelationSave"
                        class="primary"
                        text
                        type="submit"
                        @click="deletePricingZoneRelationItemConfirm">
                        {{ $t('buttons.yes') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <td class="text-center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-tooltip left>
                      <template v-slot:activator="{ on: tooltipOn }">
                        <v-btn text v-on="{ ...on, ...tooltipOn }">
                          <v-icon> mdi-dots-vertical </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{ $t('buttons.actions') }}
                      </span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item @click="editPricingZoneRelationItem(item)">
                          <v-icon class="mx-1" small v-bind="attrs">mdi-pencil</v-icon>
                          <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                            {{ $t('buttons.edit') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <span>{{ $t('tooltips.edit') }}</span>
                    </v-tooltip>

                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item @click="deleteItem(item)">
                          <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                          <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                            {{ $t('buttons.delete') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <span>{{ $t('tooltips.delete') }}</span>
                    </v-tooltip>
                  </v-list>
                </v-menu>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { pricingZoneRelationsHeaders } from '@/mixins/data-table-headers';
import { defaultPricingZoneRelation } from '@/mixins/default-items';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';

export default {
  name: 'PricingZoneRelationsPage',
  components: { ButtonSubmit },
  data: () => ({
    searchPricingZoneRelationString: '',
    pricingZoneRelationDialog: false,
    pricingZoneRelationDialogDelete: false,
    pricingZoneRelationFormTitle: 'pricingZoneRelationsPage.newItem',
    editedPricingZoneRelationItem: {},
    enablePricingZoneRelationSave: true,
    loading: false,
    pricingZoneRelations: [],
    pricingZones: [],
    vehicleClasses: [],
    currencies: [],
  }),
  async created() {
    this.editedPricingZoneRelationItem = Object.assign(
      {},
      { ...defaultPricingZoneRelation, zonePricingRelationPrices: [] }
    );
    await this.loadAllPricingZones();
    await this.loadAllVehicleClasses();
    await this.loadAllCurrencies();
    await this.loadAllPricingZoneRelations();
  },
  computed: {
    pricingZoneRelationsHeaders() {
      return pricingZoneRelationsHeaders(i18n);
    },
  },
  methods: {
    async loadAllPricingZones() {
      await this.$store.dispatch('zonePricing/getAllPricingZones').then((res) => {
        this.pricingZones = res.data.map((e) => {
          return e;
        });
      });
    },

    async loadAllVehicleClasses() {
      await this.$store.dispatch('vehicleClasses/getAllVehicleClasses').then((res) => {
        this.vehicleClasses = res.data.map((item) => {
          return item;
        });
      });
    },

    async loadAllCurrencies() {
      await this.$store.dispatch('currencies/getOrganisationCurrencies').then((res) => {
        this.currencies = res.data.map((item) => {
          return item.currency;
        });
      });
    },

    async loadAllPricingZoneRelations() {
      await this.$store.dispatch('zonePricing/getAllPricingZoneRelations').then((res) => {
        this.pricingZoneRelations = res.data.map((e) => {
          e.zone_one_id = e.zone_one.id;
          e.zone_two_id = e.zone_two.id;

          // Initialize or update zonePricingRelationPrices with missing currencies/vehicle classes
          e.zonePricingRelationPrices = this.populateMissingPrices(e.zonePricingRelationPrices);
          return e;
        });
      });
    },

    populateMissingPrices(existingPrices) {
      const updatedPrices = [...existingPrices];

      // Loop through each currency and vehicle class
      this.currencies.forEach((currency) => {
        this.vehicleClasses.forEach((vehicleClass) => {
          // Check if this combination of currency and vehicle class exists
          const existingEntry = updatedPrices.find(
            (price) => price.currency_id == currency.id && price.vehicle_class_id == vehicleClass.id
          );

          // If the combination is missing, add it with default price
          if (!existingEntry) {
            updatedPrices.push({
              id: null,
              currency_id: currency.id,
              vehicle_class_id: vehicleClass.id,
              price: 0,
            });
          }
        });
      });

      return updatedPrices;
    },

    editPricingZoneRelationItem(item) {
      this.editedPricingZoneRelationItem = this.pricingZoneRelations.indexOf(item);
      this.editedPricingZoneRelationItem = Object.assign({}, item);
      this.pricingZoneRelationFormTitle = item.zone_one.name + ' - ' + item.zone_two.name;
      this.pricingZoneRelationDialog = true;
    },

    deleteItem(item) {
      this.editedPricingZoneRelationItem = this.pricingZoneRelations.indexOf(item);
      this.editedPricingZoneRelationItem = Object.assign({}, item);
      this.pricingZoneRelationDialogDelete = true;
    },

    async savePricingZoneRelation() {
      this.enablePricingZoneRelationSave = false;
      this.loading = true;
      let path = 'savePricingZoneRelation';

      await this.$store
        .dispatch('zonePricing/' + path, this.editedPricingZoneRelationItem)
        .then(() => {
          this.loadAllPricingZoneRelations();
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          this.closePricingZoneRelationEdit();
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.enablePricingZoneRelationSave = true;
          this.loading = false;
          this.pricingZoneRelationFormTitle = 'pricingZoneRelationsPage.newItem';
        });
    },

    async deletePricingZoneRelationItemConfirm() {
      await this.$store
        .dispatch('zonePricing/deletePricingZoneRelation', this.editedPricingZoneRelationItem)
        .then(() => {
          this.loadAllPricingZoneRelations();
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
          this.closePricingZoneRelationDelete();
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.closePricingZoneRelationDelete();
        });
    },

    closePricingZoneRelationEdit() {
      this.pricingZoneRelationDialog = false;
      this.$nextTick(() => {
        this.editedPricingZoneRelationItem = Object.assign(
          {},
          { ...defaultPricingZoneRelation, zonePricingRelationPrices: [] }
        );
      });
      this.$refs.form.reset();
      this.pricingZoneRelationFormTitle = 'pricingZoneRelationsPage.newItem';
    },

    closePricingZoneRelationDelete() {
      this.pricingZoneRelationDialogDelete = false;
      this.$nextTick(() => {
        this.editedPricingZoneRelationItem = Object.assign(
          {},
          { ...defaultPricingZoneRelation, zonePricingRelationPrices: [] }
        );
      });
    },

    handleZoneSelected() {
      if (
        this.editedPricingZoneRelationItem.zone_one_id &&
        this.editedPricingZoneRelationItem.zone_two_id &&
        !this.editedPricingZoneRelationItem.id
      ) {
        this.editedPricingZoneRelationItem.zonePricingRelationPrices = [];
        this.currencies.forEach((currency) => {
          this.vehicleClasses.forEach((vehicleClass) => {
            this.editedPricingZoneRelationItem.zonePricingRelationPrices.push({
              id: null,
              currency_id: currency.id,
              vehicle_class_id: vehicleClass.id,
              price: 0,
            });
          });
        });
      }
    },

    getCurrencyName(item) {
      let index = this.currencies.findIndex((x) => x.id == item);
      return this.currencies[index]?.name
        ? this.currencies[index]?.name + '(' + this.currencies[index]?.symbol + ')'
        : '';
    },

    getVehicleClassName(item) {
      let index = this.vehicleClasses.findIndex((x) => x.id == item);
      return this.vehicleClasses[index]?.name ? this.vehicleClasses[index]?.name : '';
    },

    filteredPrices(prices, currency) {
      return prices.filter((item) => item.currency_id == currency.id);
    },
  },

  watch: {
    pricingZoneRelationDialog(val) {
      val || this.closePricingZoneRelationEdit();
    },
    'editedPricingZoneRelationItem.zone_one_id': {
      handler() {
        this.handleZoneSelected();
      },
      deep: false,
    },
    'editedPricingZoneRelationItem.zone_two_id': {
      handler() {
        this.handleZoneSelected();
      },
      deep: false,
    },
  },
};
</script>
